import React, { useState } from 'react';
import Card from './card';
import styles from '../styles/scss/components/specialistBlock.module.scss'
import QuickModal from "../components/quickModal";
import { Link } from "gatsby"
import Imgix from "react-imgix"
import Cookies from 'universal-cookie'

const AdBlock = ({ data, useFullWidth = false }) => {
    const [modalIndex, setModalIndex] = useState(-1)
    const [showModal, setShowModal] = useState(false) 
    
    return (
        <div
            className={useFullWidth ? styles.EveryUseMainContainer_full : styles.EveryUseMainContainer}
        >
            <div className={styles.MainFlexContainer}>
                {data.map((item, index) => {
                    const cookies = new Cookies();
                    const modalAlreadyShown = cookies.get(`modal-${index}`)
                    return (
                        <div key={index} style={{ display: item.onloadmodal ? 'none' : 'grid', width: '100%' }}>
                            {
                               ( (item.onloadmodal && modalAlreadyShown === undefined) || (item.openmodal && showModal)) &&
                                <QuickModal
                                    onloadmodal={item.onloadmodal}
                                    index={index}
                                    showModalIndex={modalIndex}
                                    setModalIndex={setModalIndex}
                                    setShowModal={setShowModal}>
                                    {
                                        item.onloadmodal ?
                                            <Card
                                                onloadmodal={item.onloadmodal}
                                                isModalEnabled={item.openmodal}
                                                setShowModal={setShowModal}
                                                setModalIndex={setModalIndex}
                                                key={index}
                                                index={index}
                                                type="adblock"
                                                title={item.yellowText}
                                                subtitle={item.whiteText}
                                                backgrounds={{ desktop: item.desktopBackground, tablet: item.tabletBackground, mobile: item.mobileBackground }}
                                                cta={item.ctaButtonUrl}
                                                ctaText={item.ctaButtonTitle}
                                                bodyText={item.content}
                                                target={item.ctaButtonUrl.startsWith("http") ? '_blank' : '_self'}
                                            />
                                            :
                                            item.modalAsset &&
                                            <Link to={item.modalCta}>
                                                <Imgix
                                                    src={item.modalAsset.url}
                                                    height={450}
                                                    width={450}
                                                    htmlAttributes={{alt: 'WD40 AD'}}
                                                />
                                            </Link>
                                    }

                                </QuickModal>
                            }
                            <Card
                                theming={item.theming || 'Top'}
                                desktopPosition={item.desktopPosition || 'Top'}
                                mobilePosition={item.mobilePosition || 'Above CTA'}
                                isModalEnabled={item.openmodal}
                                setShowModal={setShowModal}
                                setModalIndex={setModalIndex}
                                adImage={item.adImage}
                                key={index}
                                index={index}
                                type="adblock"
                                title={item.whiteText}
                                subtitle={item.yellowText}
                                backgrounds={{ desktop: item.desktopBackground, tablet: item.tabletBackground, mobile: item.mobileBackground }}
                                cta={item.ctaButtonUrl}
                                ctaText={item.ctaButtonTitle}
                                ctaPosition={item.ctaPosition}
                                bodyText={item.content}
                                target={item.ctaButtonUrl.startsWith("http") ? '_blank' : '_self'}
                                isFullWidth={item.fullWidth}
                                swapTitleSubtitle={item.swapTitleSubtitle}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default React.memo(AdBlock);