import React from 'react';
import styles from '../styles/scss/components/newHeroHeader.module.scss';

const NewHeroHeader = ({props}) => {
    return (
        <div className={styles.heroContainer} {...props}>
            <video
                autoPlay
                loop
                muted
                className={styles.heroVideo}
            >
                <source
                    src="https://s3.us-west-2.amazonaws.com/files.wd40.com/wd40HeroVideo.mp4"
                    type="video/mp4"
                />
            </video>
            {/* <div className={styles.title}>
                <p>NEXT-LEVEL PERFORMANCE FOR THE <span>NEXT-LEVEL PRO</span></p>
            </div> */}
        </div>
    );
}

export default NewHeroHeader;
